<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">分销管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">我的分销</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">用户列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <!-- <div class="searchboxItem">
              <span class="itemLabel">报名单位名称:</span>
              <el-input
                v-model="comName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入报名单位名称"
                clearable
              />
            </div> -->
            <div>
              <!-- <el-button type="primary" @click="getData()">查询</el-button> -->
              <el-button type="primary" @click="bindUser()">绑定用户</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="姓名"
                align="center"
                prop="userName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="手机号码"
                align="center"
                prop="mobile"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="身份证号码"
                align="center"
                prop="idcard"
              />
              <el-table-column
                label="绑定时间"
                align="center"
                prop="createTime"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <el-form
        :model="bindData"
        :rules="rules"
        ref="bindData"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户手机号" prop="bindPhone" style="width: 300px">
          <el-input
            v-model="bindData.bindPhone"
            size="small"
            placeholder="请输入用户手机号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="bgc-bv"
          size="small"
          round
          style="padding: 8px 30px"
          @click="bindUserClose"
          >取 消</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          size="small"
          style="padding: 8px 30px"
          @click="bindUserSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data: function () {
    // 自定义校验 - 手机号
    let phone = (rule, value, callback) => {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号!"));
      }
    };
    return {
      courseId: this.$route.query.courseId,
      orderId: this.$route.query.orderId,
      productCompanyId: this.$route.query.productCompanyId,
      tableData: [],
      dialogVisible: false, // 绑定用户的弹窗
      bindData: {
        bindPhone: "", // 绑定用户 - 用户手机号
      },
      // 校验
      rules: {
        // 手机号码
        bindPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: phone, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseId: this.courseId,
        orderId: this.orderId,
      };
      this.doFetch(
        {
          url: "/biz/card/wallet/queryBindUser",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 绑定用户
    bindUser() {
      this.dialogVisible = true;
    },
    // 绑定用户 - 弹框 - 关闭
    bindUserClose() {
      this.bindData.bindPhone = "";
      this.dialogVisible = false;
    },
    // 绑定用户 - 弹框 - 确定
    bindUserSubmit() {
      this.$refs.bindData.validate((valid) => {
        if (valid) {
          this.$post(
            "/biz/card/wallet/bindUser",
            {
              courseId: this.courseId,
              orderId: this.orderId,
              productCompanyId: this.productCompanyId,
              mobile: this.bindData.bindPhone,
            },
            3000,
            true,
            2
          ).then((res) => {
            this.getData();
            if (res.status == 0) {
              this.bindUserClose();
              this.$message({
                message: "绑定成功!",
                type: "success",
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style>
</style>